import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environmentUrls } from '../../../environments/environment';
import { ApiUserDetailDto, UserFilter } from '../model/apiUser.model';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private http: HttpClient) { }

  getUsers() {
    return this.http.get<ApiUserDetailDto[]>(environmentUrls.users);
  }

  editUser(user: ApiUserDetailDto) {
    return this.http.put(environmentUrls.users + '/' + String(user.id), user);
  }

  deleteUser(user: ApiUserDetailDto) {
    return this.http.delete(environmentUrls.users + '/' + String(user.id));
  }

  getUser(id: string) {
    return this.http.get<ApiUserDetailDto>(environmentUrls.users + '/' + String(id));
  }

  getMe() {
    return this.http.get<ApiUserDetailDto>(environmentUrls.users + '/me');
  }

  addUser(user: ApiUserDetailDto) {
    return this.http.post(environmentUrls.users, user);
  }

  getFilteredUsers(filter: UserFilter) {
    return this.http.get<ApiUserDetailDto[]>(this.buildGetRequestUrl(filter));
  }

  buildGetRequestUrl(filter: UserFilter) {
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(filter)) {
      if (value !== null && value !== undefined && value.length !== 0) {
        params.append(key, value.toString());
      }
    }

    const urlWithParams = `${environmentUrls.audit}?${params.toString()}`;
    return urlWithParams;
  }
}