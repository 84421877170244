<!-- <swiper [direction]="'vertical'" [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="false" [navigation]="false">
  <ng-template data-swiper-autoplay="2500" swiperSlide>
    <div class="d-flex h-100">
      <img src="assets/images/giftools.gif" alt="gif" />
      <h6 class="mb-0 f-w-400"><span class="font-primary">Check EPIC InnoLabs! </span><span class="f-light"> <a class="ms-1" href="https://epicinnolabs.hu/" target="_blank">Website</a> </span></h6>
      <i class="icon-arrow-top-right f-light"></i>
    </div>
  </ng-template>
  <ng-template data-swiper-autoplay="5000" swiperSlide>
    <div class="d-flex h-100">
      <img src="assets/images/giftools.gif" alt="gif" />
      <h6 class="mb-0 f-w-400"><span class="f-light">Check EPIC InnoLabs! </span></h6>
      <a class="ms-1" href="https://epicinnolabs.hu/" target="_blank">Website</a>
    </div>
  </ng-template>
</swiper> -->
<h4 *ngIf="isHomeLoaded">{{ home.title }}</h4>
<h4 *ngIf="!isHomeLoaded">EPIC InnoLabs</h4>
