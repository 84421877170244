import { Component, Input } from "@angular/core";


@Component(
    {
        selector: 'app-loading-spinner',
        templateUrl: "./loading-spinner.html",
        styleUrls: ['./loading-spinner.scss']
    }
)
export class LoadingSpinnerComponent {

    @Input() scaleValue: number = 1;

}