<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card" [style.background-image]="'url(' + defaulImageUrl + ')'">
          <div class="justify-content-center">
            <div>
            </div>
            <div class="login-main justify-content-center">

              <a class="logo" routerLink='/'>
                <img [src]="home.logoLightURL" alt="{{home.name}} logo" *ngIf="isImageLoaded" />
                <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage"
                  *ngIf="!isImageLoaded" />
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"
                  *ngIf="!isImageLoaded" />
              </a>
              <!-- Headers -->
              <form class="theme-form" *ngIf="!passwordChangeing">
                <h4 *ngIf="passwordFill">{{ 'authentication.reset.create' | translate}}</h4>
                <h4 *ngIf="passwordChangeing">{{ 'authentication.reset.sending' | translate}}</h4>
                <h4 *ngIf="passwordChanged">{{ 'authentication.reset.changed' | translate}}</h4>
                <h4 *ngIf="passwordChangeError">{{ 'authentication.reset.failed' | translate}}</h4>

                <!-- Messages -->
                <span class="mt-4" *ngIf="passwordFill">
                  {{ 'authentication.reset.enter_new_password' | translate}}
                </span>
                <span class="mt-4" *ngIf="passwordChangeing">
                  {{ 'authentication.reset.working_on' | translate}}
                </span>
                <span class="mt-4" *ngIf="passwordChanged">
                  {{ 'authentication.reset.confirmed' | translate}}
                </span>
                <span class="mt-4" *ngIf="passwordChangeError">
                  {{ 'authentication.reset.change_failed' | translate}}
                </span>
                <div class="spinner-container" *ngIf="passwordChangeing">
                  <app-loading-spinner class="justify-content-center" [scaleValue]="1"></app-loading-spinner>
                </div>

                <!-- Forms -->
                <div class="form-group" [formGroup]="loginForm">

                  <div class="input-group mt-4" *ngIf="passwordFill || passwordChangeError">
                    <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
                      placeholder="*********" formControlName="password" autocomplete="new-password">
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span>{{ 'authentication.show' | translate}}</span></div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span>{{ 'authentication.hide' | translate}}</span></div>
                  </div>

                  <div class="input-group mt-4" *ngIf="passwordFill || passwordChangeError">
                    <input class="form-control" [type]="show2 ? 'text' : 'password'" name="login[password2]"
                      placeholder="*********" formControlName="password2" autocomplete="new-password">
                    <div class="show-hide" (click)="showPassword2()" *ngIf="!show2"><span>{{ 'authentication.show' | translate}}</span></div>
                    <div class="show-hide" (click)="showPassword2()" *ngIf="show2"><span>{{ 'authentication.hide' | translate}}</span></div>
                  </div>

                  <!-- Error messages -->
                  <div *ngIf="loginForm.get('password').touched && loginForm.get('password').errors">
                    <p class="text-danger" *ngIf="loginForm.get('password').hasError('required')">{{ 'authentication.reset.required' | translate}}
                    </p>
                    <p class="text-danger" *ngIf="loginForm.get('password').hasError('lowercase')">{{ 'authentication.reset.lowercase' | translate}}</p>
                    <p class="text-danger" *ngIf="loginForm.get('password').hasError('uppercase')">{{ 'authentication.reset.uppercase' | translate}}</p>
                    <p class="text-danger" *ngIf="loginForm.get('password').hasError('nonAlphanumeric')">{{ 'authentication.reset.non_alphanumeric' | translate}}</p>
                    <p class="text-danger" *ngIf="loginForm.get('password').hasError('minLength')">{{ 'authentication.reset.min_char' | translate}}</p>
                    <p class="text-danger" *ngIf="loginForm.get('password').hasError('maxLength')">{{ 'authentication.reset.max_char' | translate}}</p>
                  </div>
                  <p class="text-danger"
                    *ngIf=" loginForm.get('password').touched &&
                                                   loginForm.get('password2').touched && 
                                                   loginForm.get('password').value != loginForm.get('password2').value ">
                                                   {{ 'authentication.reset.not_matching' | translate}}</p>

                  <!-- Green success message -->
                  <div *ngIf="passwordChanged" class="alert alert-success mt-4" role="alert">
                    {{ 'authentication.reset.green_success' | translate}}
                  </div>

                  <!-- Red error message -->
                  <div *ngIf="passwordChangeError" class="alert alert-danger mt-4" role="alert">
                    {{ 'authentication.reset.red_error' | translate}}
                  </div>
                </div>

                <div class="form-group mb-0">
                  <button class="btn btn-primary d-block w-100" type="submit" (click)="submit()" *ngIf="passwordFill"
                    [disabled]="!loginForm.valid || loginForm.get('password').value != loginForm.get('password2').value">{{ 'authentication.reset.done' | translate}}</button>
                </div>

                <p class="mt-4 mb-0">{{ 'authentication.forgot.remember' | translate}}<a class="ms-2" [routerLink]="'/authentication/login'">{{ 'authentication.sign_in' | translate}}</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>