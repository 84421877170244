<div class="container-fluid p-0">
    <div class="row">
		<div class="col-12">
			<div class="login-card" [style.background-image]="'url(' + defaulImageUrl + ')'">
				<div>
					<div class="login-main" [ngClass]="isLoading? 'center' :'' ">
						<div>
							<a class="logo" routerLink="/">
							<img [src]="home.logoLightURL" alt="{{home.name}} logo" *ngIf="isImageLoaded" />
							<img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="loginpage"
								*ngIf="!isImageLoaded" />
							<img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="loginpage"
								*ngIf="!isImageLoaded" />
							</a>
							<app-loading-spinner [scaleValue]="1.5" *ngIf="isLoading"></app-loading-spinner>
						</div>
						<form class="theme-form" *ngIf="!isLoading">
							<h4>Hello {{user.firstName}}!</h4>
							<p>{{ 'authentication.operator.passcode' | translate}}</p>
							<code-input class="mt-4 mb-4"
								[isCodeHidden]="true"
								[codeLength]="8"
								(codeChanged)="onCodeChanged($event)"
								(codeCompleted)="onCodeCompleted($event)">
							</code-input>
						</form>
					</div>
				</div>
			</div>
		</div>
    </div>
</div>
