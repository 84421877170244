<div class="container-fluid p-0">
    <div class="login-card">
      <div>
        <div class="login-card" [style.background-image]="'url(' + defaulImageUrl + ')'">
          <div>
            <div>
              <a class="logo" routerLink="/">
                <img [src]="home.logoLightURL" alt="{{home.name}} logo" *ngIf="isImageLoaded" />
                <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage"  *ngIf="!isImageLoaded"/>
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" *ngIf="!isImageLoaded" />
              </a>
            </div>
        <div class="login-main" [ngClass]="isLoading?  'center':''">
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
          <form class="theme-form" [formGroup]="installForm" *ngIf="!isLoading" (submit)="register()">
            <h4>Install your system</h4>
            <p>Enter system details to costumize system</p>
            <div *ngIf="errorMessage" class="alert alert-danger"> {{errorMessage}} </div>
            <div class="form-group  ">
              <label class="col-form-label pt-0">Install code</label>
              <div class="row ">
                <input class="form-control" type="text" placeholder="Install code" formControlName="firstName">
                <div class="text text-danger"
                  *ngIf=" !installForm.get('firstName').valid && installForm.get('firstName').touched">
                  Enter install code!</div>
              </div>
            </div>
            <h5>General</h5>
            <div class="form-group">
              <label class="col-form-label">Host</label>
              <input class="form-control" type="text" placeholder="http://localhost:80" formControlName="host">
              <div class="text text-danger" *ngIf="!installForm.get('host').valid && installForm.get('host').touched">
                Enter a valid host!</div>
            </div>
            <div class="form-group">
              <label class="col-form-label">API host</label>
              <input class="form-control" type="text" placeholder="http://localhost:8080" formControlName="apiHost">
            </div>
            <div class="form-group">
              <label class="col-form-label">Language</label>
              <input class="form-control" type="text" placeholder="HU" formControlName="language">
            </div>

            <div class="form-group">
              <label class="col-form-label">Time Zone</label>
              <input class="form-control" type="text" placeholder="+2" formControlName="timeZone">
            </div>


            <h5>Postgres</h5>
            <div class="form-group">
              <label class="col-form-label">Host</label>
              <input class="form-control" type="text" placeholder="mes-db" formControlName="host">
            </div>
            <div class="form-group">
              <label class="col-form-label">Port</label>
              <input class="form-control" type="text" placeholder="5432" formControlName="port">
            </div>
            <div class="form-group">
              <label class="col-form-label">User</label>
              <input class="form-control" type="text" placeholder="postgres" formControlName="user">
            </div>
            <div class="form-group">
              <label class="col-form-label">Password</label>
              <input class="form-control" type="text" placeholder="example" formControlName="password">
            </div>
            <div class="form-group">
              <label class="col-form-label">Database</label>
              <input class="form-control" type="text" placeholder="HU" formControlName="database">
            </div>

            <h5>SMTP</h5>
            <div class="form-group">
              <label class="col-form-label">Host</label>
              <input class="form-control" type="text" placeholder="smpt-host" formControlName="host">
            </div>
            <div class="form-group">
              <label class="col-form-label">Port</label>
              <input class="form-control" type="text" placeholder="5432" formControlName="port">
            </div>
            <div class="form-group">
              <label class="col-form-label">Use TTL</label>
              <input class="form-control" type="text" placeholder="TRUE" formControlName="port">
            </div>
            <div class="form-group">
              <label class="col-form-label">User</label>
              <input class="form-control" type="text" placeholder="service@epicinnolabs.hu" formControlName="user">
            </div>
            <div class="form-group">
              <label class="col-form-label">Password</label>
              <input class="form-control" type="text" placeholder="example" formControlName="password">
            </div>
            <div class="form-group">
              <label class="col-form-label">Name</label>
              <input class="form-control" type="text" placeholder="MES Service" formControlName="name">
            </div>

            <h5>Notification</h5>
            <div class="form-group">
              <label class="col-form-label">SMS Host</label>
              <input class="form-control" type="text" placeholder="sms-host" formControlName="host">
            </div>
            <div class="form-group">
              <label class="col-form-label">SMS Key</label>
              <input class="form-control" type="text" placeholder="8gjxdMV" formControlName="port">
            </div>
            <div class="form-group">
              <label class="col-form-label">Teams Hook</label>
              <input class="form-control" type="text" placeholder="service@epicinnolabs.hu" formControlName="user">
            </div>
            <div class="form-group">
              <label class="col-form-label">Slack Hook</label>
              <input class="form-control" type="text" placeholder="example" formControlName="password">
            </div>

            <h5>API Keys</h5>
            <div class="form-group">
              <label class="col-form-label">Backup Key</label>
              <input class="form-control" type="text" placeholder="h8gjxdMVLLPsI99ZV3g" formControlName="name">
            </div>
            <div class="form-group">
              <label class="col-form-label">Mapbox Key</label>
              <input class="form-control" type="text" placeholder="PsI99ZVh8gjxdMVLL3g" formControlName="name">
            </div>

            <h5>JWT</h5>
            <div class="form-group">
              <label class="col-form-label">Issuer</label>
              <input class="form-control" type="text" placeholder="EPIC InnoLabs" formControlName="name">
            </div>
            <div class="form-group">
              <label class="col-form-label">Audiance</label>
              <input class="form-control" type="text" placeholder="EPIC InnoLabs MES User" formControlName="name">
            </div>
            <div class="form-group">
              <label class="col-form-label">TTL</label>
              <input class="form-control" type="text" placeholder="60000" formControlName="name">
            </div>
            <div class="form-group">
              <label class="col-form-label">Secreat</label>
              <input class="form-control" type="text" placeholder="VeryVeryVeryKey" formControlName="name">
            </div>

            <div class="form-group mb-0">
              <div class="checkbox ">
                <input id="checkbox1" type="checkbox" formControlName="privacyPolicy">
                <label for="checkbox1">Agree with<a class="ms-2" routerLink='/'>Privacy Policy</a></label>
              </div>
              <button class="btn btn-primary d-block w-100" type="submit"
                [disabled]="!installForm.valid">Install</button>
            </div>
            <p class="mt-4 mb-0">System already installed?<a class="ms-2" [routerLink]="'/authentication/login'">Sign
                in</a></p>
          </form>
        </div>
      </div>
    </div>
</div>