import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AdminGuard  {
  constructor(public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let token = localStorage.getItem("jwt");
    if (!token || token === null) {
      this.router.navigate(["/authentication/login"]);
      return true;
    } else if (token) {
      if (!Object.keys(token).length) {
        this.router.navigate(["/authentication/login"]);
        return true;
      }
    }
    return true;
  }
}
