import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Home } from 'src/app/shared/model/home.model';
import { HomeService } from 'src/app/shared/services/home.service';
import { environmentUrls } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  passwordChanged: boolean = false;
  passwordChangeError: boolean = false;
  passwordChangeing: boolean = false;
  passwordFill: boolean = true;
  password: string;
  show: boolean = false;
  show2: boolean = false;
  isImageLoaded: boolean = false;
  home: Home;
  defaulImageUrl: string;
  loginForm: FormGroup;
  token: string;
  email: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private homeService: HomeService) {

    this.route.queryParams.subscribe(params => {
      try {
        this.token = params['token'];
        this.email = params['email'];
        console.log(this.token);
        console.log(this.email);
      } catch (error) {
        console.error('Error occurred while handling route params:', error);
      }
    });

    this.defaulImageUrl = "assets/images/login/login_bg-original.jpg";

  }

  ngOnInit() {
    this.getHome();
    this.loginForm = new FormGroup({
      'password': new FormControl('', [this.customPasswordValidator]),
      'password2': new FormControl('', [Validators.required])
    });

  }

  showPassword() {
    this.show = !this.show;
  }

  showPassword2() {
    this.show2 = !this.show2;
  }

  submit() {
    console.log(this.token);
    console.log(this.email);
    this.password = this.loginForm.value["password"];
    this.resetStates();
    this.passwordChangeing = true;


    this.authService.changePassword(this.token, this.password, this.email).subscribe(
      (value: any) => {
        this.success();
        console.log("Password changed successfully");
        console.log(value);
        setTimeout(() => {
          this.router.navigate(["/authentication/login"]);
        }, 5000);
      },
      (error) => {
        console.error( error);
        this.failed();
      }
    );
  }

  success() {
    this.resetStates();
    this.passwordChanged = true;
  }

  failed() {
    this.resetStates();
    this.passwordChangeError = true;

  }

  resetStates() {
    this.passwordChangeing = false;
    this.passwordChanged = false;
    this.passwordChangeError = false;
    this.passwordFill = false;
  }

  customPasswordValidator(control: FormControl): { [s: string]: boolean } {
    const value: string = control.value;
    let hasError = false;
    let errorPool: { [s: string]: boolean } = {};
    if (value == null || !/(?=.*[a-z])/.test(value)) {
      errorPool['lowercase'] = true;
      hasError = true;
    }
    if (!/(?=.*[A-Z])/.test(value)) {
      errorPool['uppercase'] = true;
      hasError = true;
    }
    if (!/(?=.*[^a-zA-Z0-9])/.test(value)) {
      errorPool['nonAlphanumeric'] = true;
      hasError = true;
    }
    if (value == null || value?.length < 6) {
      errorPool['minLength'] = true;
      hasError = true;
    }
    if (value?.length > 15) {
      errorPool['maxLength'] = true;
      hasError = true;
    }
    return hasError ? errorPool : null;
  }

  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.backgroundImageURL = "\"" + environmentUrls.documentPublic + this.home.backgroundImageURL + "\"";
        this.defaulImageUrl = this.home.backgroundImageURL;
        this.isImageLoaded = true;
      });
  }

}