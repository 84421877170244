import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/pages/authentication/auth.service";
import { ApiUserDetailDto } from "src/app/shared/model/apiUser.model";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public user: ApiUserDetailDto;
  isUserLoaded = false;
  
  constructor(public router: Router, private authService: AuthService, private userService: UserService) {  }

  ngOnInit() { 
    this.userService.getMe().subscribe(user => 
      {
        this.user = user; 
        if(!user.imageURL)
        this.user.imageURL = "http://www.gravatar.com/avatar/?d=mp";
        if(!user.position)
          this.user.position = "User" 
        this.isUserLoaded = true;
      });
  }

  logoutFunc() {
    this.authService.logout();
    this.router.navigateByUrl('authentication/login');
  }
}