import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sorting-indicator',
  templateUrl: './sorting-indicator.component.html',
  styleUrls: ['./sorting-indicator.component.scss']
})
export class SortingIndicatorComponent {

  @Input() isAscending = true;




}
