<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card" [style.background-image]="'url(' + defaulImageUrl + ')'">
          <div class="justify-content-center">
            <div class="login-main justify-content-center">
              <div>
                <a class="logo" routerLink='/'>
                  <img [src]="home?.logoLightURL" alt="{{home?.name}} logo" *ngIf="isImageLoaded" />
                  <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage"
                    *ngIf="!isImageLoaded" />
                  <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"
                    *ngIf="!isImageLoaded" />
                </a>
              </div>
              <h4 *ngIf="confirmationInProgress">{{ 'authentication.confirm_email.confirm_in_progress' | translate }}</h4>
              <h4 *ngIf="emailConfirmed">{{ 'authentication.confirm_email.confirmed' | translate }}</h4>
              <h4 *ngIf="emailConfirmedError">{{ 'authentication.confirm_email.confirm_failed' | translate }}</h4>
              <h4 *ngIf="resent">{{ 'authentication.confirm_email.confirm_sent' | translate }}</h4>
              <h4 *ngIf="resendInProcess">{{ 'authentication.confirm_email.sending' | translate }}</h4>
              <h4 *ngIf="resendFailed">{{ 'authentication.confirm_email.resend_failed' | translate }}</h4>

              <span class="mt-4" *ngIf="confirmationInProgress">
                {{ 'authentication.confirm_email.working_on_confirm' | translate }}
              </span>
              <span class="mt-4" *ngIf="resent">
                {{ 'authentication.confirm_email.email_resent' | translate }}
              </span>
              <span class="mt-4" *ngIf="resendInProcess">
                {{ 'authentication.confirm_email.sending_new_email' | translate }}
              </span>
              <span class="mt-4" *ngIf="resendFailed">
                {{ 'authentication.confirm_email.send_failed' | translate }}
              </span>
              <span class="mt-4" *ngIf="emailConfirmed">
                {{ 'authentication.confirm_email.email_confirmed' | translate }}
              </span>
              <span class="mt-4" *ngIf="emailConfirmedError">
                {{ 'authentication.confirm_email.email_confirm_failed' | translate }}
              </span>

              <div class="spinner-container" *ngIf="confirmationInProgress || resendInProcess">
                <app-loading-spinner class="justify-content-center" [scaleValue]="1"></app-loading-spinner>
              </div>

              <!-- Green success message -->
              <div *ngIf="emailConfirmed" class="alert alert-success mt-4" role="alert">
                {{ 'authentication.confirm_email.confirm_success' | translate }}
              </div>
              <div *ngIf="resent" class="alert alert-success mt-4" role="alert">
                {{ 'authentication.confirm_email.email_sent' | translate }}
              </div>

              <!-- Red error message -->
              <div *ngIf="emailConfirmedError" class="alert alert-danger mt-4" role="alert">
                {{ 'authentication.confirm_email.failed_to_confirm' | translate }}
              </div>
              <div *ngIf="resendFailed" class="alert alert-danger mt-4" role="alert">
                {{ 'authentication.confirm_email.failed_to_resend' | translate }}
              </div>


              <div class="form-group mb-0" *ngIf="emailConfirmedError || resendFailed">
                <button class="btn btn-primary d-block w-100" type="submit" (click)="resend()">{{ 'authentication.confirm_email.resend' | translate }}</button>
              </div>

              <p class="mt-4 mb-0">{{ 'authentication.confirm_email.login' | translate }}<a class="ms-2" [routerLink]="'/authentication/login'">{{ 'authentication.sign_in' | translate }}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>