import { Pipe, PipeTransform } from '@angular/core';
import { Region } from '../model/location.model';

@Pipe({
  name: 'regionPipe'
})
export class RegionPipe implements PipeTransform {

  transform(value: unknown, regions: Region[]): string {
    var region = regions.find(r => r.id == value);
    return region.regionName;
  }

}
