import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../model/location.model';

@Pipe({
  name: 'countryPipe'
})
export class CountryPipe implements PipeTransform {

  transform(value: unknown, countries: Country[]): string {
    var country = countries.find(r => r.id == value);
    return country.countryName;
  }

}
