import { Menu } from "../model/menu.model"
import { MSPAdminMenuItems } from "./msp.menu" 
import { KoronaMenuItems } from "./korona.menu"
import { BarcodeMenuItems } from "./barcode.menu"
import { FittingkerMenuItems } from "./fittingker.menu"
import { NadudvariMenuItems } from "./nadudvari.menu"
import { FSMenuItems } from "./fs.menu"
import { ERPMenuItems } from "./erp.menu"
import { CRMMenuItems } from "./crm.menu"
import { MESMenuItems } from "./mes.menu"
import { ETCMenuItems } from "./etc.menu"
import { KnorrMenuItems } from "./knorr.menu"

export var AllMenuItems: Menu[] = [...MSPAdminMenuItems, ...KoronaMenuItems, ...FittingkerMenuItems, ...NadudvariMenuItems, ...KnorrMenuItems, ...FSMenuItems, ...ERPMenuItems, ...CRMMenuItems, ...MESMenuItems, ...BarcodeMenuItems]