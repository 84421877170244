<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card" [style.background-image]="'url(' + defaulImageUrl + ')'">
          <div>
            <div class="login-main" [ngClass]="isLoading? 'center' : ''">
              <div>
                <a class="logo" routerLink="/">
                  <img [src]="home.logoLightURL" alt="{{home.name}} logo" *ngIf="isImageLoaded" />
                  <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage"
                    *ngIf="!isImageLoaded" />
                  <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"
                    *ngIf="!isImageLoaded" />
                </a>
              </div>
              
              <form class="theme-form" [formGroup]="forgotPasswordForm" (submit)="forgotPassword()">

                <!-- Header -->
                <h4 *ngIf="fill">{{ 'authentication.forgot.reset' | translate}}</h4>
                <h4 *ngIf="changeing">{{ 'authentication.forgot.sending' | translate}}</h4>
                <h4 *ngIf="changed">{{ 'authentication.forgot.sent' | translate}}</h4>
                <h4 *ngIf="changeError">{{ 'authentication.forgot.failed' | translate}}</h4>

                <span class="mt-4" *ngIf="fill">{{ 'authentication.forgot.enter_email' | translate}}</span>
                <span class="mt-4" *ngIf="changeing">{{ 'authentication.forgot.working_on' | translate}}</span>
                <span class="mt-4" *ngIf="changed">{{ 'authentication.forgot.email_sent' | translate}}</span>
                <span class="mt-4" *ngIf="changeError">{{ 'authentication.forgot.reset_failed' | translate}}</span>

                <div class="spinner-container" *ngIf="changeing">
                  <app-loading-spinner></app-loading-spinner>
                </div>


                <div class="form-group" *ngIf="!changed && !changeing">
                  <label class="col-form-label">{{ 'authentication.email' | translate}}</label>
                  <input class="form-control" formControlName="email">
                </div>

                <!-- Green success message -->
                <div *ngIf="changed" class="alert alert-success mt-4" role="alert">
                  {{ 'authentication.forgot.green_success' | translate}}
                </div>

                <!-- Red error message -->
                <div *ngIf="changeError" class="alert alert-danger mt-4" role="alert">
                  {{ 'authentication.forgot.red_error' | translate}}
                </div>

                <div
                  *ngIf="forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').hasError('required')"
                  class="alert alert-danger">
                  {{ 'common.required' | translate}}
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary d-block w-100" type="submit" [disabled]="!forgotPasswordForm.valid"
                    *ngIf="fill || changeError">{{ 'authentication.forgot.send' | translate}}</button>
                </div>
                <p class="mt-4 mb-0">{{ 'authentication.forgot.remember' | translate}}<a class="ms-2" [routerLink]="'/authentication/login'">{{ 'authentication.sign_in' | translate}}</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>