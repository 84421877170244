import { Pipe, PipeTransform } from '@angular/core';
import { PackagingFormat } from '../model/MSP/msp-packingFormat.model';

@Pipe({
  name: 'packingformat'
})
export class PackingformatPipe implements PipeTransform {

  transform(value: unknown, packingFormats: PackagingFormat[]): string {
    let format = packingFormats.find(r => r.id == value);
    return format?.formatType;
  }

}
