import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { AuthService } from './pages/authentication/auth.service';
import { HomeService } from './shared/services/home.service';
import { Home } from './shared/model/home.model';
import { environmentUrls } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  home: Home;

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private loader: LoadingBarService, private authUser: AuthService, private homeService: HomeService, private titleService: Title, private translate: TranslateService) {
    this.authUser.resolveToken();
    //this.getHome();
    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    // }
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.getHome();
  }

  getHome(){
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.faviconURL = environmentUrls.documentPublic + this.home.faviconURL;
        this.titleService.setTitle(this.home.title);
        const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
        link.href = this.home.faviconURL;
      });
  }
}