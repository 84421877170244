import { Component } from '@angular/core';

@Component({
  selector: 'app-dice-icon',
  templateUrl: './dice-icon.component.html',
  styleUrls: ['./dice-icon.component.scss']
})
export class DiceIconComponent {

}
