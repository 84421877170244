import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environmentUrls } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SubRoleModel } from '../model/subRoleModel';
import { FunctionAccessModel } from '../model/functionAccessModel';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RoleService {
  myRole: Observable<string>;
  subrole: Observable<SubRoleModel>;
  mySubRole: SubRoleModel;


  constructor(private http: HttpClient, private router: Router) { }

  getRoles() {
    return this.http.get<string[]>(environmentUrls.roles + "/roles");
  }

  getSubRoles() {
    return this.http.get<SubRoleModel[]>(environmentUrls.roles + "/subroles");
  }

  getMyRole() {
    if (this.subrole == null) {
      this.myRole = this.http.get<string>(environmentUrls.roles + "/me/role");
      return this.myRole;
    } else {
      return this.myRole;
    }
  }

  getMySubRole(): Observable<SubRoleModel> {
    if (this.subrole == null) {
      this.subrole = this.http.get<SubRoleModel>(environmentUrls.roles + "/me/subrole");
      return this.subrole;
    } else {
      return this.subrole;
    }
  }

  // getFunction(functionName: string) : FunctionAccessModel {
  //   this.getMySubRole().subscribe(r => {
  //     this.mySubRole = r; 
  //     console.log( "My subrole: "+ this.mySubRole.name);
  //     var tmp = this.mySubRole.functionAccesses.find(access => access.name === functionName);
  //     console.log(tmp);
  //     return tmp;
  //   }, error => {
  //     console.log("Error in getFunction: " + error);
  //     console.log(error);
  //     return null;
  //   });
  //   console.log("Returning null");
  //   return null;
  // }

  getFunction(functionName: string): Observable<FunctionAccessModel> {
    return new Observable(observer => {
      this.getMySubRole().subscribe(
        r => {
          this.mySubRole = r;
          console.log("My subrole: " + this.mySubRole.name);
          var tmp = this.mySubRole.functionAccesses.find(access => access.name === functionName);
          console.log(tmp);
          observer.next(tmp); // Emit the result
          observer.complete(); // Complete the observable
        },
        error => {
          console.log("Error in getFunction: " + error);
          console.log(error);
          observer.error(error); // Emit an error
        }
      );
    });
  }

  getMyMenuId() {
    this.getMySubRole().subscribe(r => {
      this.mySubRole = r;
      return this.mySubRole.menuId;
    });
    return null;
  }

  checkFunctionRead(functionName: string): boolean {

    //return this.getFunction(functionName).read;
    this.getFunction(functionName).subscribe(s => {
      console.log(s.read);
      return s.read;
    }, error => {
      return false;
    });

    return false;
  }

  checkFunctionWrite(functionName: string) {

    //return this.getFunction(functionName).write;
    var tmp = this.getFunction(functionName).subscribe(s => {
      return s.write;
    }, error => {
      return false;
    });
  }

  chackFunctionCreate(functionName: string) {

    //return this.getFunction(functionName).create;
    var tmp = this.getFunction(functionName).subscribe(s => {
      return s.create;
    }, error => {
      return false;
    });
  }

  checkFunctionDelete(functionName: string) {

    //return this.getFunction(functionName)._delete;
    var tmp = this.getFunction(functionName).subscribe(s => {
      return s._delete;
    }, error => {
      return false;
    });
  }

  checkFunctionUpdate(functionName: string) {
    //return this.getFunction(functionName).update;
    var tmp = this.getFunction(functionName).subscribe(s => {
      return s.update;
    }, error => {
      return false;
    });
  }

  functionLoad(functionName: string): boolean {
    return this.checkFunctionRead(functionName);
  }
}

export class RoleFunction {
  public static readonly MSPAdminCenter = "MSPAdminCenter";
  public static readonly MSPPartner = "MSPPartner";
  public static readonly MSPProduct = "MSPProduct";
  public static readonly MSPProductProperty = "MSPProductProperty";
  public static readonly MSPSchemas = "MSPSchemas";
}
