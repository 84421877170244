import { title } from "process";
import { Menu } from "../model/menu.model"

export var BarcodeMenuItems: Menu[] = [
  {
    headTitle1: "Barcode Project",
  },
  {
    title: "Dashboards",
    icon: "home",
    type: "link",
    path: "/barcode-dashboard",
    active: false,
  },
  {
    title: "Products",
    type: 'link',
    icon: "ecommerce",
    active: false,
    path: "/barcoded-products/list",
  },
  {
    title: "Storage Areas",
    type: 'link',
    icon: "table",
    active: false,
    path: "/storage-areas/list",
  },
  {
    title: "Stock Informations",
    type: 'sub',
    icon: "job-search",
    active: false,
    children: [
      {
         title: "Table",
         type: 'link',
         path: "/stock-informations/list",
      },
      {
        title: "Diagram",
        type: 'link',
        path: "/stock-informations/diagram",
     },
    ]
  },
  {
    title: "Warehouse Transactions",
    type: 'sub',
    icon: "social",
    active: false,
    children: [
      {
        title: "Table",
        type: 'link',
        path: "/warehouse-transactions/list",
      },
      {
        title: "Diagram",
        type: 'link',
        path: "/warehouse-transactions/diagram",
      },
    ]
  }
];