import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from "src/app/pages/authentication/auth.service";
import { Home } from 'src/app/shared/model/home.model';
import { HomeService } from 'src/app/shared/services/home.service';
import { environmentUrls } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  changed: boolean = false;
  changeError: boolean = false;
  changeing: boolean = false;
  fill: boolean = true;

  forgotPasswordForm: FormGroup
  errorMessage: string;
  isLoading: boolean = false;
  isImageLoaded: boolean = false;
  home: Home;
  defaulImageUrl: string;

  constructor(private authService: AuthService, private router: Router, private homeService : HomeService) {
    this.defaulImageUrl = "assets/images/login/login_bg-original.jpg";
   }

  ngOnInit() {
    this.getHome();
    this.forgotPasswordForm = new FormGroup(
      {
        'email': new FormControl(null, [Validators.required, Validators.email])
      }
    );
  }

  forgotPassword() {
    this.resetStates();
    this.fill = false;
    this.changeing = true;

    this.authService.forgotPassword(this.forgotPasswordForm.get('email').value).subscribe(
      (value: any) => {
        this.success();
        console.log(value);
      },
      (error) => {
        this.failed();
        console.log(error);
      }
      );
  }

  failed() {
    this.resetStates();
    this.changeError = true;
  }

  success() { 
    this.resetStates();
    this.changed = true;
    setTimeout(() => {
      this.router.navigate(["/authentication/login"]);
    }, 5000);
  }

  resetStates() {
    this.changed = false;
    this.changeing = false;
    this.changeError = false;
    this.fill = false;
  }
  
  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.backgroundImageURL = "\""+environmentUrls.documentPublic + this.home.backgroundImageURL +"\"";
        this.defaulImageUrl = this.home.backgroundImageURL;
        this.isImageLoaded = true;
      });
  }
}