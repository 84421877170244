import { Component, OnInit } from "@angular/core";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../auth.service"
import { Observable } from "rxjs";
import { User } from "../user.model";
import { Home } from "../../../shared/model/home.model"
import { HomeService } from "../../../shared/services/home.service"
import { environmentUrls } from '../../../../environments/environment';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  newUser = false;
  loginForm: FormGroup;
  show: boolean = false
  errorMessage: string;
  loginObs: Observable<User>;
  isLoading: boolean = false;
  isImageLoaded: boolean = false;
  home: Home;
  defaulImageUrl: string;


  constructor(public router: Router, private authService: AuthService, private homeService: HomeService, private translateService: TranslateService) {
    //this.router.navigate(['authentication/install']);
    this.defaulImageUrl = "assets/images/login/login_bg-original.jpg";
  }

  ngOnInit() {
    this.getHome();
    this.loginForm = new FormGroup({
      'email': new FormControl("", [Validators.required, Validators.email]),
      'password': new FormControl('', [Validators.required]),
      'rememberPassword': new FormControl(null, []),
    });
  }

  login() {
    this.errorMessage = null;
    this.isLoading = true;
    this.loginObs = this.authService.login(this.loginForm.value["email"], this.loginForm.value["password"]);
    this.loginObs.subscribe(response => {
      this.router.navigate(["/home"]); 
    }
      , error => {
        this.errorMessage = error;
        this.isLoading = false;
      });
  }

  log() {
    console.log(this.loginForm);
  }

  showPassword() {
    this.show = !this.show
  }

  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.backgroundImageURL = "\""+environmentUrls.documentPublic + this.home.backgroundImageURL +"\"";
        this.defaulImageUrl = this.home.backgroundImageURL;
        this.isImageLoaded = true;
        this.translateService.use(this.home.defaultLanguage);
        console.log(this.home);
      });
  }
}