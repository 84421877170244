import { Pipe, PipeTransform } from '@angular/core';
import { State } from '../model/location.model';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  transform(value: unknown, states: State[]): any {
    let state = states.find(r => r.id == value);
    if (state === undefined) {
      return value;
    }
    else {
      return state.stateName;
    }
  }

}
