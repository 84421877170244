<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card" [style.background-image]="'url(' + defaulImageUrl + ')'">
        <div>
          <div class="login-main" [ngClass]="isLoading? 'center' :'' ">
            <div>
              <a class="logo" routerLink="/">
                <img [src]="home.logoLightURL" alt="{{home.name}} logo" *ngIf="isImageLoaded" />
                <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage"
                  *ngIf="!isImageLoaded" />
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"
                  *ngIf="!isImageLoaded" />
              </a>
              <app-loading-spinner [scaleValue]="1.5" *ngIf="isLoading"></app-loading-spinner>
            </div>
            <form class="theme-form" [formGroup]="loginForm" *ngIf="!isLoading" (submit)="login()">

              <h4>{{ 'authentication.sign_in_to' | translate}}</h4>
              <p>{{ 'authentication.enter' | translate}}</p>
              <div class="form-group">
                <label class="col-form-label">{{ 'authentication.email' | translate}}</label>
                <input class="form-control" type="email" placeholder="{{ 'authentication.placeholders.email' | translate}}" formControlName="email" />
                <div *ngIf="loginForm.get('email').touched && loginForm.get('email').hasError('required')"
                class="text text-danger mt-1">{{ 'common.required' | translate}}</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                class="text text-danger mt-1">{{ 'common.attributes.email_invalid' | translate}}</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">{{ 'authentication.password' | translate}}</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password"
                placeholder="{{ 'authentication.placeholders.password' | translate}}" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span>{{ 'authentication.show' | translate}}</span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span>{{ 'authentication.hide' | translate}}</span></div>
                
              </div>
              <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
              class="text text-danger mt-1">{{ 'common.required' | translate}}</div>
              <div class="alert alert-danger" *ngIf="errorMessage"> {{errorMessage}} </div>
              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" type="submit">
                    <span>{{ 'authentication.sign_in' | translate}}</span></button>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                
                <!-- <div class="">
                  <p class="mt-4 mb-0">
                    <a class="ml-2" [routerLink]="'/authentication/register'">Create Account</a>
                  </p>
                </div> -->
                <div class="">
                  <p class="mt-4 mb-0 "><a class="mr-2" [routerLink]="'/authentication/forgot-password'">{{ 'authentication.forgot_password' | translate}}</a></p>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>