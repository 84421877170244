import { Injectable } from '@angular/core';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    private toastComponent: ToastComponent;

    constructor( ) { }

    setToastComponent(toastComponent: ToastComponent) {
        this.toastComponent = toastComponent;
    }

    showErrorToast(message: string) {
        this.showToast(message, 'error');
    }
    
    showSuccessToast(message: string) {
        this.showToast(message, 'success');
    }

    private showToast(message: string, type: string) {
        if(this.toastComponent) {
            this.toastComponent.addMessage(message, type);
        } else {
            console.error('Toast component not set');
        }
    }
}