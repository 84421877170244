<div class="media profile-media"  *ngIf="isUserLoaded">
  <img class="img-40 rounded-circle" src={{user.imageURL}} alt="">
  <div class="media-body"><span>{{user.firstName}} {{user.lastName}}  <i class="middle fa fa-angle-down"></i></span>
    <p class="mb-0 font-roboto"> {{ user.position }}  </p> 
  </div> 
</div>
<div class="media profile-media"  *ngIf="!isUserLoaded">
  <img class="img-40 rounded-circle" src="http://www.gravatar.com/avatar/?d=mp" alt="">
  <div class="media-body"><span>User Name  <i class="middle fa fa-angle-down"></i></span>
    <p class="mb-0 font-roboto"> Postition </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li> -->
  <li *ngIf="isUserLoaded"><a href="/users/edit/{{user.id}}"><app-feather-icons [icon]="'user'" ></app-feather-icons><span>{{ 'header.profile.profile' | translate }}</span></a></li>
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <li><a href="/support"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>{{'header.profile.faq' | translate}}</span></a></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>{{'header.profile.log_out' | translate}}</span></li>
</ul>