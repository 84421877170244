import { Menu } from "../model/menu.model"

export var KoronaMenuItems: Menu[] = [
  {
    headTitle1: "Korona Gomba",
  },
  {
    title: "Dashboards",
    icon: "home",
    type: "link",
    path: "/korona-dashboard",
    active: false,
  },
  {
    title: "menu.korona.mushroomHouses",
    icon: "animation",
    active: true,
    path: "/houses/list",
    type: "sub",
    children: [
      { path: "houses/list", title: "menu.list", type: "link" },
      // { path: "/resource-datas/diagram", title: "menu.diagrams", type: "link" },
      { path: "/machine-locations", title: "menu.locations", type: "link" },
      { path: "houses/map", title: "menu.map", type: "link" },
    ],
  },

  {
    title: "menu.korona.grow",
    type: 'sub',
    icon: "project",
    active: false,
    children: [
      {
        title: "menu.korona.growCycles",
        type: 'link',
        icon: "project",
        active: false,
        path: "/cycles/list",
      },
      {
        title: "menu.korona.harvests",
        type: 'link',
        icon: "",
        active: false,
        path: "/harvests/list",
      },
      {
        title: "menu.korona.soils",
        type: 'link',
        icon: "",
        active: false,
        path: "/soils/list",
      },
      {
        title: "menu.korona.types",
        type: 'link',
        icon: "",
        active: false,
        path: "/types/list",
      },
      {
        title: "menu.korona.enrichmentMaterials",
        type: 'link',
        icon: "",
        active: false,
        path: "/enrichmentMaterials/list",
      },
      {
        title: "Betegségek",
        type: 'link',
        icon: "",
        active: false,
        path: "/diseases/list",
      }
    ],
  },
  {
    title: "menu.korona.spawns",
    type: 'sub',
    icon: "others",
    active: false,
    children: [
      {
        title: "menu.korona.spawnLabs",
        type: 'sub',
        icon: "others",
        active: false,
        children: [
          {
            title: "menu.korona.spawnLabs",
            type: 'link',
            icon: "",
            active: false,
            path: "/spawnLabs/list",
          },
          {
            title: "menu.korona.materials",
            type: 'link',
            icon: "",
            active: false,
            path: "/materials/list",
          },
          {
            title: "menu.korona.miceliums",
            type: 'link',
            icon: "",
            active: false,
            path: "/miceliums/list",
          },
        ],
      },
      {
        title: "menu.korona.spawnFactories",
        type: 'sub',
        icon: "others",
        active: false,
        children: [
          {
            title: "menu.korona.spawnFactories",
            type: 'link',
            icon: "",
            active: false,
            path: "/spawnFactories/list",
          },
          {
            title: "menu.korona.spawnMaterials",
            type: 'link',
            icon: "",
            active: false,
            path: "/spawnMaterials/list",
          },
        ],
      },
      {
        title: "menu.korona.spawnTypes",
        type: 'link',
        icon: "",
        active: false,
        path: "/spawns/list",
      },
      {
        title: "menu.korona.klavCodes",
        type: 'link',
        icon: "",
        active: false,
        path: "/klavCodes/list",
      },
      
    ],
  },
  {
    title: "menu.korona.composts",
    type: 'sub',
    icon: "others",
    active: false,
    children: [
      {
        title: "menu.korona.compostLabs",
        type: 'link',
        icon: "",
        active: false,
        path: "/compostLabs/list",
      },
      {
        title: "menu.korona.composts",
        type: 'link',
        icon: "",
        active: false,
        path: "/composts/list",
      },
    ],
  },
  {
    title: "menu.korona.peat",
    type: 'sub',
    icon: "others",
    active: false,
    children: [
      {
        title: "menu.korona.peats",
        type: 'link',
        icon: "",
        active: false,
        path: "/peats/list",
      },
      {
        title: "menu.korona.peatLabs",
        type: 'link',
        icon: "",
        active: false,
        path: "/peatLabs/list",
      },
    ],
  },
  {
    title: "menu.korona.trucks",
    type: "link",
    path: "/korona-trucks/list",
    icon: "social",
    active: false,
  },
  {
    title: "menu.statistics",
    type: 'sub',
    icon: "charts",
    active: false,
    children: [
      {
        title: "Szedések",
        type: 'link',
        path: "/korona-statistics/harvests",
      },
      {
        title: "Komposzt",
        type: 'link',
        path: "/korona-statistics/compost",
      },
      {
        title: "Hullámok",
        type: 'link',
        path: "/korona-statistics/waves",
      },
      {
        title: "Betelepítések",
        type: 'link',
        path: "/korona-statistics/cycles",
      },
    ],
  },
  {
    title: "menu.adminCenter",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      // { path: "b", title: "menu.settings", type: "link" },
      // { path: "a", title: "menu.migrations", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
      { path: "home/edit", title: "menu.appearance", type: "link" },
    ],
  },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: 'menu.support',
        path: "/support",
        icon: 'support-tickets',
        type: 'link',
      },
      // {
      //   title: 'menu.impressum',
      //   path: "/impressum",
      //   icon: 'faq',
      //   type: 'link',
      // },
    ],
  },
];