<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>{{ title | translate }}</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </a>
          </li>
          <ng-container *ngFor="let item of items; index as i">
            <li class="breadcrumb-item" *ngIf="links && links[i] !== undefined">
              <a (click)="navigate(links[i])">
                {{ item | translate }}
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="!(links && links[i] !== undefined)">
              {{ item | translate }}
            </li>
          </ng-container>
          <li class="breadcrumb-item active">{{ active_item | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
