import { Pipe, PipeTransform } from '@angular/core';
import { ProductCategory } from '../model/productCategory.model';

@Pipe({
  name: 'productcategory'
})
export class ProductCategoryPipe implements PipeTransform {

  transform(value: number, categories: ProductCategory[]) {
    for (const element of categories) {
      if (element.id == value) {
        return element.productCategoryName;
      }
    }
  }
}
