import { Pipe, PipeTransform } from '@angular/core';
import { PackagingFormat } from '../model/MSP/msp-packingFormat.model';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: unknown, formats: PackagingFormat[]): string {
    var format = formats.find(r => r.id == value);
    if (format === undefined) {
      return "";
    }
    return format.formatType;
  }
}
