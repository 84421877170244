import { Menu } from "../model/menu.model"

export const ShiftempoMenuItems: Menu[] = [
  {
    headTitle1: "Shiftempo",
  },
//   {
//     title: "menu.dashboard",
//     icon: "home",
//     type: "link",
//     path: "/",
//     active: true,
//   },
  {
    title: "Products",
    icon: "ecommerce",
    type: "link",
    active: false,
    path: "/shiftempo-products/list",
  },
  {
    title: "Orders",
    type: "link",
    icon: "task",
    active: false,
    path: "/shiftempo-work-in-progresses/list"
  },
  {
    title: "Operators",
    icon: "social",
    type: "sub",
    active: false,
    children: [
      { path: "/shiftempo-operators/list", title: "Operators", type: "link" },
      { path: "/shiftempo-operators/skills", title: "Operator Skills", type: "link" },
    ],
  },
  {
    title: "Plans",
    icon: "widget",
    active: false,
    type: "sub",
    children: [
        { path: "/shiftempo-plans/candidates", title: "Plans", type: "link" },
        { path: "/shiftempo-plans/new", title: "Add New Plan", type: "link" },
    ],
  },
  {
    title: "menu.adminCenter",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      // { path: "b", title: "menu.settings", type: "link" },
      // { path: "a", title: "menu.migrations", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
    ],
  },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: 'menu.support',
        path: "/support",
        icon: 'support-tickets',
        type: 'link',
      },
      {
        title: 'menu.impressum',
        path: "/impressum",
        icon: 'faq',
        type: 'link',
      },
    ],
  }
];