import { Menu } from "../model/menu.model"


export var KnorrMenuItems: Menu[] = [
  {
    headTitle1: "Knorr-Bremse",
  },
  {
    title: "menu.dashboard",
    icon: "home",
    type: "link",
    path: "/fittingker-dashboard/fittingker",
    active: true,
  },
  // {
  //   title: "Termékek",
  //   icon: "ecommerce",
  //   badgeType: "light-success",
  //   badgeValue: "Új",
  //   type: "link",
  //   path: "/fittingker-products/list",
  //   active: false
  // },
  {
    title: "Erőforrások",
    icon: "social",
    badgeType: "light-success",
    badgeValue: "Új",
    type: "sub",
    active: false,
    children: [
      // { path: "/fittingker-machines/list/partners", title: "Partnerek", type: "link" },
      // { path: "/holiday", title: "Naptár", type: "link" },
      // { path: "/operators/list", title: "Operators", type: "link" },
      { path: "/fittingker-machines/list/machines", title: "Machines", type: "link" },
      { path: "/resources/machines", title: "Machines", type: "link" },
      { path: "/machines", title: "Machines", type: "link" },
      { path: "/resources/machines/locations", title: "Machine Locations", type: "link" },
      { path: "/capacities", title: "Kapacitások", type: "link" },
      // { path: "/machine-locations", title: "Machine Locations", type: "link" },
      { path: "/resources/andons", title: "Andons", type: "link" },
      // { path: "/resources/tools", title: "Tools", type: "link" },
      { path: "/fs/resource-tools", title: "Tools", type: "link" },
      { path: "/fs/resource-tools/scan", title: "Tool Scan", type: "link" },
      // { path: "plans/tasks", title: "Tasks", type: "link" },
      // { path: "/fittingker-mastertasks/list", title: "Mester tábla", type: "link" },
      // { path: "/fittingker-machine-tasks/list", title: "Erőforrás feladatok", type: "link" },
      // { path: "/fittingker-machines/gantt", title: "Gantt diaggram", type: "link" },
      // { path: "/fittingker-machines/timeline", title: "Idővonal", type: "link" },
    ],
  },
  {
    title: 'Monitoring',
      type: "sub",
      badgeType: "light-success",
      badgeValue: "New",
    active: false,
    icon: "social",
    children: [
      // { path: "nadudvari/resources", title: 'menu.resources', type: 'link', bookmark: true },
      { path: "nadudvari-machine-data/production", title: 'menu.nadudvar.production', type: 'link', bookmark: true },
      { path: "nadudvari-machine-data/control-changes", title: 'menu.nadudvar.control_changes', type: 'link', bookmark: true },
      { path: "nadudvari-machine-data/errors", title: 'menu.nadudvar.errors', type: "link", bookmark: true }
    ]
  },
  {
    title: "Gyártási feladatok",
    icon: "social",
    badgeType: "light-success",
    badgeValue: "Új",
    type: "sub",
    active: false,
    children: [
      // { path: "/fittingker-mastertasks/list", title: "Mester tábla", type: "link" },
      { path: "/fittingker-machine-tasks/list", title: "Erőforrásonként", type: "link" },
      { path: "/fittingker-machines/gantt", title: "Gantt", type: "link" },
      { path: "/fittingker-machines/timeline", title: "Idővonal", type: "link" },
    ],
  },
  {
    title: "Project",
    icon: "project",
    type: "link",
    path: "/project/list",
  },
  { path: "/calender", title: "Calender", icon: "calender", type: "link", bookmark: true },
  { path: "/todo", title: "Todo", icon: "to-do", type: "link" },
  { path: "/support-ticket", title: "Tickets", icon: "support-tickets", type: "link" },
  {
    title: "menu.statistics",
    icon: "charts",
    badgeType: "light-secondary",
    badgeValue: "In process",
    type: "sub",
    active: false,
    children: [
      { path: "/fittingker-statistics", title: "Erőforrások", type: "link" },
      { path: "chart/apex", title: "Operation", type: "link" },
      { path: "chart/chartjs", title: "Products", type: "link" },
      { path: "chart/chartist", title: "Maintanance", type: "link" },
    ],
  },
  {
    title: "menu.adminCenter",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      // { path: "b", title: "menu.settings", type: "link" },
      // { path: "a", title: "menu.migrations", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
      { path: "home/edit", title: "menu.appearance", type: "link" },
    ],
  },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: 'menu.support',
        path: "/support",
        icon: 'support-tickets',
        type: 'link',
      },
      {
        title: 'menu.impressum',
        path: "/impressum",
        icon: 'faq',
        type: 'link',
      },
    ],
  }
  // {
  //     title: "MasterTasks",
  //     icon: "social",
  //     badgeType: "light-secondary",
  //     badgeValue: "In process",
  //     type: "sub",
  //     active: false,
  //     children: [
  //         { path: "/fittingker-mastertasks/list", title: "Master Tasks list", type: "link" },
  //     ],
  // },
];