import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Home } from 'src/app/shared/model/home.model';
import { HomeService } from 'src/app/shared/services/home.service';
import { environmentUrls } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { User } from '../../user.model';
import { ApiUser } from 'src/app/shared/model/apiUser.model';

@Component({
  selector: 'app-operator-login',
  templateUrl: './operator-login.component.html',
  styleUrls: ['./operator-login.component.scss']
})
export class OperatorLoginComponent {
  newUser = false;
  loginForm: FormGroup;
  errorMessage: string;
  loginObs: Observable<User>;
  isLoading: boolean = false;
  isImageLoaded: boolean = false;
  home: Home;
  defaulImageUrl: string;
  id: string;
  user: ApiUser;

  constructor(
    public router: Router,
    private authService: AuthService, 
    private homeService: HomeService,
    private route: ActivatedRoute
  ) {
    this.defaulImageUrl = "assets/images/login/login_bg-original.jpg";
    this.route.params.subscribe(params => { this.id = params['id']; });
  }

  ngOnInit() {
    this.getHome();
    this.isLoading = true;
    this.authService.getOperator(this.id).subscribe({
      next: (r) => {
        this.user = r;
        this.isLoading = false;
      },
      error: (error) => {
        this.errorMessage = error;
        this.isLoading = false;
      }
    });
  }

  login(code: string) {
    this.errorMessage = null;
    this.isLoading = true;
    this.authService.login(this.user.email, code).subscribe({
      next: response => {
        this.router.navigate(["/home"]); 
      },
      error: error => {
        this.errorMessage = error;
        this.isLoading = false;
      }
    });
    // this.loginObs = this.authService.operatorLogin(this.id, code);
    // this.loginObs.subscribe({
    //   next: response => {
    //     this.router.navigate(["/home"]); 
    //   },
    //   error: error => {
    //     this.errorMessage = error;
    //     this.isLoading = false;
    //   }
    // });
  }

  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.backgroundImageURL = "\""+environmentUrls.documentPublic + this.home.backgroundImageURL +"\"";
        this.defaulImageUrl = this.home.backgroundImageURL;
        this.isImageLoaded = true;
      });
  }

  onCodeChanged(code: string) {
  }

  onCodeCompleted(code: string) {
    this.login(code);
  }
}
