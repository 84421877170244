import { Component } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
  messages: ToastMessage[] = [];
  constructor() { }

  closeToast(message: ToastMessage) {
    this.removeMessage(message);
  }

  addMessage(message: string, type: string) {
    const msg: ToastMessage = {
      message: message,
      status: "show",
      isExpanded: false,
      type: type,
    }
    this.messages.push(msg);
    setTimeout(() => {
      this.removeMessage(msg);
    }, 20000);
  }

  private removeMessage(message: ToastMessage) {
    this.messages = this.messages.filter(msg => msg !== message);
  }

  copyMessage(message: ToastMessage) {
    const el = document.createElement('textarea');
    el.value = JSON.stringify(message.message);
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  getBackgroundColor(message: ToastMessage) {
    switch (message.type) {
      case 'error': return '#ffcccd'; break;
      case 'success': return '#b6fcb8'; break;
    }
  }

}

export class ToastMessage {
  message: string;
  status: string;
  isExpanded: boolean;
  type: string;
}