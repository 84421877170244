import { Menu } from "../model/menu.model"


  export var FSMenuItems: Menu[] = [
    {
      headTitle1: "Factory Service",
    },
    {
      title: "menu.dashboard",
      icon: "home",
      type: "link",
      path: "/msp-dashboard/msp",
      active: true,
    },
    {
      title: "menu.partners",
      type: "sub",
      active: false,
      icon: "contact",
      children: [
        { path: "/partners", title: "menu.companies", type: 'link', bookmark: true },
        { path: "/partner-groups", title: "menu.companyGroups", type: 'link', bookmark: true },
        { path: "/contact", title: "menu.contacts", type: "link", bookmark: true },
        { path: "/partners/map", title: "menu.map", type: "link", bookmark: true }
      ]
    },
    {
      title: "menu.sales",
      icon: "ecommerce",
      type: "sub",
      active: false,
      children: [
        { path: "sales/leads", title: "menu.leads", type: "link" },
        { path: "sales/opportunities", title: "menu.opportunities", type: "link" },
      ],
    },
    {
      title: "menu.projects", 
      type: 'link', 
      icon: "project", 
      path: "/projects/list" },
    {
      title: "menu.resources",
      icon: "social",
      type: "sub",
      children: [
        { path: "/machines", title: "menu.machines", type: "link" },
        { path: "/machine-locations", title: "menu.locations", type: "link" },
        { path: "/fs/resource-tools", title: "menu.tools", type: "link" },
        { path: "/fs/resource-tools/scan", title: "menu.toolScan", type: "link" },
      ],
    },
    {
      title: "menu.adminCenter",
      icon: "user",
      type: "sub",
      children: [
        { path: "users", title: "menu.users", type: "link" },
        { path: "audit/list", title: "menu.auditLogs", type: "link" },
        { path: "backup/list", title: "menu.backups", type: "link" },
        { path: "health", title: "menu.health", type: "link" },
      ],
    },
    {
      title: "Help",
      icon: "support-tickets",
      type: "sub",
      children: [
        {title: 'menu.support', path: "/support", icon: 'support-tickets', type: 'link'},
        {title: 'menu.impressum', path: "/impressum", icon: 'faq', type: 'link'},
      ],
    },
  ];