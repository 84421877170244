import { Pipe, PipeTransform } from '@angular/core';
import { ProductSchema } from '../model/MSP/msp-productSchema.model';


@Pipe({
  name: 'schema'
})
export class SchemaPipe implements PipeTransform {

  transform(value: unknown, schemas: ProductSchema[]): string {
    var schema = schemas.find(r => r.id == value);
    return schema.codeName;
  }


}
