<div class="loadingio-spinner-wedges-anz3ot3grnk" [style.transform]="'scale(' + scaleValue + ')'">
    <div class="ldio-z3xdnw5uy">
        <div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
        </div>
    </div>
</div>