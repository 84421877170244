import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from '../model/unit.model';

@Pipe({
  name: 'unit'
})
export class UnitPipe implements PipeTransform {

  transform(value: unknown, units: Unit[]): string {
    var unit = units?.find(r => r.id == value);
    return unit?.unitName;
  }
}