import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, tap, throwError } from 'rxjs';
import { User } from './user.model';
import { ApiUser, ChangePasswordDto } from '../../shared/model/apiUser.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environmentUrls } from 'src/environments/environment';
import { JsonPipe } from '@angular/common';
import { json } from 'stream/consumers';

export interface Authresponse {
    userId: string;
    token: string;
    refreshToken: string;
}


@Injectable({ providedIn: "root" })
export class AuthService {

    token;
    user = new BehaviorSubject<User>(null);

    constructor(private httpClient: HttpClient) { }

    login(email: string, password: string) {
        return this.httpClient.post<User>(environmentUrls.login, { Email: email, Password: password })
            .pipe(tap(response => this.handleAuthentication(response)), catchError(this.handleLoginErrors));
    }
    logout() {
        localStorage.clear();
        this.user.next(null);
    }
    register(id: string, firstName: string, lastName: string, email: string, password: string) {
        return this.httpClient.post(environmentUrls.register
            , { Id: id, FirstName: firstName, LastName: lastName, Email: email, Password: password })
            .pipe(catchError(this.handleRegistrationErrors));
    }
    forgotPassword(email: string) {
        return this.httpClient.post(environmentUrls.forgotPassword + email, {})
            .pipe(catchError(this.handleForgotPasswordError));
    }

    changePassword(token: string, password: string, email: string) {
        return this.httpClient.post(environmentUrls.auth + "/resetPassword?token=" + token + "&email=" + email + "&password=" + password, {});
    }

    changePasswordIn(id: string, passwordOld: string, passwordNew: string) {
        
        console.log(id);
        console.log({ oldPassword: passwordOld, newPassword: passwordNew });
        return this.httpClient.post(environmentUrls.users +"/"+id +"/changePassword", { oldPassword: passwordOld, newPassword: passwordNew });
    }

    changeEmail(id: string, email: string) {
        return this.httpClient.post(environmentUrls.users +"/"+id +"/changeEmail/"+email, {});
    }

    confirmEmail(token: string, email: string) {
        return this.httpClient.post(environmentUrls.auth + "/confirmEmail?token=" + token + "&email=" + email, {});
    }

    changeRole(id: string, role: string) {
        return this.httpClient.post(environmentUrls.users +"/"+id +"/changeRole/"+role, {});
    }

    changeSubRole(id: string, role: string) {
        return this.httpClient.post(environmentUrls.users +"/"+id +"/changeSubRole/"+role, {});
    }


    requestConfirmEmail(email: string) {
        return this.httpClient.post(environmentUrls.auth + "/requestConfirmEmail?email=" + email, {});
    }

    private handleLoginErrors(errorRes: HttpErrorResponse) {
        console.log(errorRes);
        let err = "";
        if (errorRes.error.errors && errorRes.error.errors.Password) {
            err = err.concat(errorRes.error.errors.Password[0]);
        }
        if (errorRes.status == 401) {
            err = "Email or password is incorrect."
        }
        if (err.length == 0) {
            err = "Unkown error"
        }
        return throwError(() => new Error(err));
    }

    private handleRegistrationErrors(errorRes: HttpErrorResponse) {
        console.log(errorRes);
        let err = "";
        if (errorRes.error.errors && errorRes.error.errors.Password) {
            err = err.concat(errorRes.error.errors.Password[0]);
        }
        if (errorRes.error.PasswordRequiresDigit) {
            err = err.concat(errorRes.error.PasswordRequiresDigit[0]);
        }
        if (errorRes.error.PasswordRequiresNonAlphanumeric) {
            err = err.concat(errorRes.error.PasswordRequiresNonAlphanumeric[0]);
        }
        if (errorRes.error.PasswordRequiresUpper) {
            err = err.concat(errorRes.error.PasswordRequiresUpper[0]);
        }
        if (errorRes.error.PasswordRequiresLower) {
            err = err.concat(errorRes.error.PasswordRequiresLower[0]);
        }
        if (errorRes.error.DuplicateEmail) {
            err = err.concat(errorRes.error.DuplicateEmail[0]);
        }
        if (errorRes.status == 500) {
            err = "Internal server error";
        }
        if (errorRes.status == 200) {
            err = "Ok";
        }
        if (err.length == 0) {
            err = "Unkown error";
        }
        return throwError(() => new Error(err));
    }

    private handleForgotPasswordError(errorRes: HttpErrorResponse) {
        let err = "";
        console.log(errorRes);
        if (errorRes.status == 200) {
            err = "Ok";
        }
        if (errorRes.status == 400) {
            err = "Bad request";
        }
        return throwError(() => new Error(err));
    }

    private handleAuthentication(response: User) {
        this.user.next(response);
        localStorage.setItem('user', JSON.stringify(response));
        localStorage.setItem('jwt', response.token);
        //console.log(localStorage.getItem('jwt'));
        this.token = response.token;
    }

    public resolveToken(){
       this.token =  localStorage.getItem('jwt');
    }

    getOperators() {
        return this.httpClient.get<ApiUser[]>(environmentUrls.users + '/getOperators');
    }

    getOperator(id: string) {
        return this.httpClient.get<ApiUser>(environmentUrls.users + '/getOperator/' + id);
    }

    operatorLogin(id: string, code: string) {
        return of(null);
    }

    private operators = [
        {
            name: "Béla",
            id: '1'
        },
        {
            name: "Sanyi",
            id: '2'
        },
        {
            name: "Józsi",
            id: '3'
        },
        {
            name: "Pista",
            id: '4'
        },
        {
            name: "Piroska",
            id: '5'
        },
        {
            name: "Kati",
            id: '6'
        }
    ];
}