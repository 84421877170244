import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Home } from 'src/app/shared/model/home.model';
import { HomeService } from 'src/app/shared/services/home.service';
import { environmentUrls } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { ApiUser } from 'src/app/shared/model/apiUser.model';

@Component({
  selector: 'app-operator-choose',
  templateUrl: './operator-choose.component.html',
  styleUrls: ['./operator-choose.component.scss']
})
export class OperatorChooseComponent implements OnInit{
  accountForm: FormGroup;
  defaulImageUrl: string;
  home: Home;
  isImageLoaded: boolean = false;
  list: ApiUser[];

  constructor(
    private homeService: HomeService,
    private router: Router,
    private authService: AuthService
  ) {
    this.defaulImageUrl = "assets/images/login/login_bg-original.jpg";
  }

  ngOnInit() {
    this.getHome();
    this.accountForm = new FormGroup({
      'account': new FormControl("", Validators.required)
    });
    this.authService.getOperators().subscribe((value) => {
      this.list = value;
    });
  }

  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.backgroundImageURL = "\""+environmentUrls.documentPublic + this.home.backgroundImageURL +"\"";
        this.defaulImageUrl = this.home.backgroundImageURL;
        this.isImageLoaded = true;
      });
  }

  next() {
    this.router.navigate(["authentication/operator-login/" + this.accountForm.value["account"]]);
  }

  onSelect(event: any) {}
}
