/**
 * MES API
 * Best backend service ever built.
 *
 * OpenAPI spec version: v1
 * Contact: info@epicinnolabs.hu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApiUser {
    id: string
    email?: string
    firstName?: string
    lastName?: string
    friendlyName?: string
    position?: string
    department?: string
    gender?: string
    address?: string
    dateOfBirth?: string //TOO date?
    imageURL?: string
  }
  

export interface ApiUserDetailDto { 
    id?: string;
    email?: string;
    emailConfirmed?: boolean;
    phoneNumber?: string;
    phoneNumberConfirmed?: string;
    firstName?: string;
    lastName?: string;
    friendlyName?: string;
    position?: string;
    department?: string;
    company?: string;
    gender?: string;
    address?: string;
    dateOfBirth?: Date;
    imageURL?: string;
    about?: string;
    role?: string;
    subRole?: string;
}

export interface ApiUserDto { 
    id?: string;
    email?: string;
    emailConfirmed?: boolean;
    phoneNumber?: string;
    phoneNumberConfirmed?: string;
    firstName?: string;
    lastName?: string;
    friendlyName?: string;
    position?: string;
    department?: string;
    company?: string;
    gender?: string;
    address?: string;
    dateOfBirth?: Date;
    imageURL?: string;
    about?: string;
}

export interface UserFilter {
    userId: string | null;
    type: string | null;
    tableName: string | null;
    primaryKey: string | null;
    fromTime: string | null;
    toTime: string | null;
    limit: number | null;
}

export interface ChangePasswordDto {
    oldPassword: string | null;
    newPassword: string | null;
}