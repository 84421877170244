import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, exhaustMap, take } from "rxjs";
import { AuthService } from "./auth.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // return this.authService.user.pipe(take(1), exhaustMap(user => {
        //     if (!user) {
        //         return next.handle(req);
        //     }
        //     let modifiedReq = req.clone({ setHeaders: { "Authorization": "Bearer " + user.token } });
        //     console.log(user.token);
        //     return next.handle(modifiedReq);
        // }));
        let token = localStorage.getItem("jwt");
        if (token) {
            
            //console.log(this.authService.token);
            const cloned = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.token}`,
                },
            });
            return next.handle(cloned);
        }
        return next.handle(req);
    }
}