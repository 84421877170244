import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tr } from 'date-fns/locale';
import { Home } from 'src/app/shared/model/home.model';
import { HomeService } from 'src/app/shared/services/home.service';
import { environmentUrls } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-confirm-email-out',
  templateUrl: './confirm-email-out.component.html',
  styleUrls: ['./confirm-email-out.component.scss']
})

export class ConfirmEmailOutComponent implements OnInit {
  emailConfirmed: boolean = false;
  emailConfirmedError: boolean = false;
  resent: boolean = false;
  resendInProcess: boolean = false;
  resendFailed: boolean = false;
  confirmationInProgress: boolean = false;
  token: string;
  email: string;
  isImageLoaded: boolean = false;
  home: Home;
  defaulImageUrl: string;


  constructor(private router: Router, 
              private route: ActivatedRoute, 
              private authService: AuthService,
              private homeService: HomeService) { 

              this.route.queryParams.subscribe( params => {
                try {
                  this.token = params['token'];
                  this.email = params['email'];
                  console.log(this.token);
                  console.log(this.email);
                } catch (error) {
                  console.error('Error occurred while handling route params:', error);
                }
              });

     this.defaulImageUrl = "assets/images/login/login_bg-original.jpg";

  }

  ngOnInit() {
    this.getHome();
    this.confirmationInProgress = true;
    console.log(this.token);
    console.log(this.email);

    this.authService.confirmEmail(this.token, this.email).subscribe(
      (value: any) => {
        this.success();
      },
      (error) => {
        this.failed();
      }
      );
  }

  failed() {
    this.resetStates();
    this.emailConfirmedError = true;
  }

  success() { 
    this.resetStates();
    this.emailConfirmed = true;
    setTimeout(() => {
      this.router.navigate(["/authentication/login"]); 
    }, 5000);
  }

  resend() {
    this.resetStates();
    this.resendInProcess = true;
    this.authService.requestConfirmEmail(this.email).subscribe(
      (value: any) => {
        this.resetStates();
        this.resent = true;
      },
      (error) => {
        this.resetStates();
        this.resendFailed = true;
      }
      );
  }

  resetStates() {
    this.emailConfirmed = false;
    this.emailConfirmedError = false;
    this.resent = false;
    this.resendInProcess = false;
    this.resendFailed = false;
    this.confirmationInProgress = false;
  }

  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.backgroundImageURL = "\""+environmentUrls.documentPublic + this.home.backgroundImageURL +"\"";
        this.defaulImageUrl = this.home.backgroundImageURL;
        this.isImageLoaded = true;
      });
  }

}