import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../model/product.model';

@Pipe({
    name: 'product'
})
export class ProductPipe implements PipeTransform {

    transform(value: number, products: Product[]) {
        for (const element of products) {
            if (element.id == value) {
                return element.productName;
            }
        }
    }
}
