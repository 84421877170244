import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() links: any[];
  @Input() active_item: string;

  constructor(
    private location: Location,
    private router: Router
  ) {
  }

  ngOnInit() {  }

  navigate(link: string) {
    if(link === 'BACK') {
      this.location.back();
    }
    else {
      this.router.navigate([link]);
    }
  }

}
