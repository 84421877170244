import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService} from '../auth.service';
import { Home } from 'src/app/shared/model/home.model';
import { HomeService } from 'src/app/shared/services/home.service';
import { environmentUrls } from 'src/environments/environment';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss']
})

export class InstallComponent implements OnInit {

  show: boolean = false;
  installForm: FormGroup;
  installObservable: Observable<any>;
  errorMessage: string;
  isLoading: boolean = false;
  isImageLoaded: boolean = false;
  home: Home;
  defaulImageUrl: string;

  constructor(private authService: AuthService, private router: Router, private homeService : HomeService) {
    this.defaulImageUrl = "assets/images/login/login_bg-original.jpg";
  }

  customPasswordValidator(control: FormControl): { [s: string]: boolean } {
    const value: string = control.value;
    let hasError = false;
    let errorPool: { [s: string]: boolean } = {};
    if (value == null || !/(?=.*[a-z])/.test(value)) {
      errorPool['lowercase'] = true;
      hasError = true;
    }
    if (!/(?=.*[A-Z])/.test(value)) {
      errorPool['uppercase'] = true;
      hasError = true;
    }
    if (!/(?=.*[^a-zA-Z0-9])/.test(value)) {
      errorPool['nonAlphanumeric'] = true;
      hasError = true;
    }
    if (value == null || value?.length < 6) {
      errorPool['minLength'] = true;
      hasError = true;
    }
    if (value?.length > 15) {
      errorPool['maxLength'] = true;
      hasError = true;
    }
    return hasError ? errorPool : null;
  }

  ngOnInit() {
    this.getHome();
    this.installForm = new FormGroup({
      'password': new FormControl(null, [this.customPasswordValidator]),
      'userId': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'firstName': new FormControl(null, [Validators.required]),
      'lastName': new FormControl(null, [Validators.required]),
      'privacyPolicy': new FormControl(null, Validators.required)
    });
  }

  install() {
    this.errorMessage = null;
    this.isLoading = true;
    this.installObservable = this.authService
      .register(this.installForm.value["userId"]
        , this.installForm.value["firstName"]
        , this.installForm.value["lastName"]
        , this.installForm.value["email"]
        , this.installForm.value["password"]);
    this.installObservable
      .subscribe(result => {
        this.router.navigate(["./authentication/login"]);
      }, errorMessage => {
        this.isLoading = false;
        console.log(errorMessage);
        if (errorMessage.message === "Ok") {
          this.router.navigate(["./authentication/login"]);
        }
        else {
          this.errorMessage = errorMessage;
        }
      })
  }

  log() {
    console.log(this.installForm.get('password'));
  }
  showPassword() {
    this.show = !this.show;
  }

  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.home.backgroundImageURL = "\""+environmentUrls.documentPublic + this.home.backgroundImageURL +"\"";
        this.defaulImageUrl = this.home.backgroundImageURL;
        this.isImageLoaded = true;
      });
  }
}